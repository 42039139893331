/** **************************************************************************************
 * Preemptive asset caching
 * ************************************************************************************** */

import { openDB } from 'idb'
import { group, unique } from 'radash'
import { Queue } from 'workbox-background-sync'

import type { IAsset } from '../schemas/vixen-assets'
import { RasterImageAsset, VectorImageAsset } from '../schemas/vixen-assets'
import { MapAsset } from '../schemas/vixen-spatial'
import type { CacheAssetsEvent } from '../types/worker'
import { debugLog } from '../utils/debug'
import getEnv from '../utils/getEnv'

/** Cache put assets in their respective cache */
function getCacheName(typename: string): string {
  switch (typename) {
    case RasterImageAsset.typename:
    case VectorImageAsset.typename:
    case MapAsset.typename:
      return 'image-cache'

    default:
      return 'media-cache'
  }
}

const cachingQueue = new Queue('caching-queue', {
  onSync: async ({ queue }) => {
    let entry: Awaited<ReturnType<typeof queue.shiftRequest>> & {
      metadata: { typename: string }
    }

    while ((entry = (await queue.shiftRequest()) as typeof entry)) {
      if (!navigator.onLine) {
        // If offline, re-queue the current entry and break the loop
        await queue.unshiftRequest(entry)
        break
      }

      try {
        const cacheName = getCacheName(entry.metadata.typename)
        const cache = await caches.open(cacheName)
        await cache.add(entry.request.url)
      } catch (error) {
        console.error('Replay failed for request', entry.request, error)

        if (!navigator.onLine) {
          // Re-queue the request for a later retry
          await queue.unshiftRequest(entry)
        }
      }
    }
  },
})

async function clearQueue(queueName: string): Promise<void> {
  const db = await openDB('workbox-background-sync')

  try {
    if (!db.objectStoreNames.contains(queueName)) {
      // The object store doesn't exist, so there's nothing to clear.
      return
    }

    const store = db.transaction(queueName, 'readwrite').objectStore(queueName)

    await store.clear()
  } finally {
    db.close()
  }
}

async function asyncCacheAssets(
  typename: string,
  assets: IAsset[],
): Promise<void> {
  const cacheName = getCacheName(typename)
  const cache = await caches.open(cacheName)

  const results = await Promise.allSettled(
    assets.map(async asset => {
      const cachedResponse = await cache.match(asset.url)
      if (!cachedResponse) {
        debugLog('Service Worker', 'Cache asset:', asset)
        await cache.add(asset.url)
      }

      return asset
    }),
  )

  results.forEach(result => {
    if (result.status === 'rejected') {
      const asset = result.reason
      console.error(`Error caching asset: ${asset.url}`, result.reason)

      if (!navigator.onLine) {
        // Re-queue the request for a later retry
        cachingQueue.pushRequest({
          request: new Request(asset.url),
          metadata: { typename },
        })
      }
    }
  })
}

export async function cacheAssets(event: CacheAssetsEvent): Promise<void> {
  const { disableCache } = getEnv()
  const { assets } = event

  if (disableCache || !assets || !assets.length) return

  await clearQueue('caching-queue')

  const assetsByTypename = group(
    unique(assets, ({ url }) => url),
    ({ ref }) => ref.typename,
  )

  for (const [typename, _assets] of Object.entries(assetsByTypename)) {
    if (!_assets) {
      continue
    }

    await asyncCacheAssets(typename, _assets)
  }
}
